import React from "react";
import Style from "./About.module.scss";
import Terminal from "./Terminal";
import { Box } from "@mui/material";
import { info } from "../../info/Info";
import axios from "axios";
import ReactDOM from "react-dom";
import Modal from "react-modal";

const customStyles = {
  overlay: {
    background: "rgba(0, 0, 0, 0.75)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "12px",
    padding: "0px",
    backgroundColor: "transparent",
    width: "100%",
    justifyContent: "center",
    display: "flex",
    border: "none",
  },
};

Modal.setAppElement("#root");

export default function About() {
  let subtitle;

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }

  const firstName = info.firstName.toLowerCase();

  const upload = function () {
    setIsOpen(true);
    // let body = { title: "React POST Request Example" };
    // axios
    //   .post("https://viacep.com.br/ws/06764290/json/", body)
    //   .then((response) => {
    //     alert(JSON.stringify(response));
    //   });
  };

  const download = () => {
    axios.get("https://viacep.com.br/ws/06764290/json/").then((response) => {
      alert(JSON.stringify(response));
    });
  };

  function upLoadModal() {
    return (
      <>
        <p>
          <span style={{ color: info.baseColor }}>Upload of curriculum $</span>{" "}
        </p>
        <p>
          <span style={{ color: info.baseColor }}>
            <input
              style={{
                color: info.baseColor,
                width: "100%",
                boxShadow: "#27c93f 0px 0px 10px 8px",
                marginTop: "12px",
                bakcgroundColor: "black",
              }}
              placeholder="Password"
              type="password"
            />
          </span>
        </p>
        <p>
          <span
            style={{
              color: info.baseColor,
            }}
          >
            {" "}
            <br />
            <input
              style={{ width: "100%" }}
              type="file"
              className={Style.green}
            />
          </span>
        </p>
        <p
          style={{
            justifyContent: "end",
            display: "flex",
          }}
        >
          <span
            style={{
              color: info.baseColor,
              marginTop: "32px",
            }}
          >
            <button className={Style.green}>Upload</button>
          </span>
        </p>
      </>
    );
  }

  function upDownLoad() {
    return (
      <>
        <p>
          <span style={{ color: info.baseColor }}>Download curriculum $</span>{" "}
        </p>
        <p>
          <span style={{ color: info.baseColor }}>
            <button onClick={download} className={Style.green}>
              Download
            </button>{" "}
            <button onClick={upload} className={Style.green}>
              Upload
            </button>
          </span>
        </p>
      </>
    );
  }

  function aboutMeText() {
    return (
      <>
        <p>
          <span style={{ color: info.baseColor }}>
            {firstName}
            {info.lastName.toLowerCase()} $
          </span>{" "}
          cat about {firstName}{" "}
        </p>
        <p>
          <span style={{ color: info.baseColor }}>
            about {firstName} <span className={Style.green}>(main)</span> ${" "}
          </span>
          {info.bio}
        </p>
      </>
    );
  }

  function skillsText() {
    return (
      <>
        <p>
          <span style={{ color: info.baseColor }}>
            {firstName}
            {info.lastName.toLowerCase()} $
          </span>{" "}
          cd skills/tools
        </p>
        <p>
          <span style={{ color: info.baseColor }}>
            skills/tools <span className={Style.green}>(main)</span> $
          </span>{" "}
          ls
        </p>
        <p style={{ color: info.baseColor }}> Proficient With</p>
        <ul className={Style.skills}>
          {info.skills.proficientWith.map((proficiency) => (
            <li key={proficiency.key}>{proficiency.description}</li>
          ))}
        </ul>
        <p style={{ color: info.baseColor }}> Exposed To</p>
        <ul className={Style.skills}>
          {info.skills.exposedTo.map((skill) => (
            <li key={skill.key}>{skill.description}</li>
          ))}
        </ul>
      </>
    );
  }

  function miscText() {
    return (
      <>
        <p>
          <span style={{ color: info.baseColor }}>
            {firstName}
            {info.lastName.toLowerCase()} $
          </span>{" "}
          cd hobbies/interests
        </p>
        <p>
          <span style={{ color: info.baseColor }}>
            hobbies/interests <span className={Style.green}>(main)</span> $
          </span>{" "}
          ls
        </p>
        <ul>
          {info.hobbies.map((hobby) => (
            <li key={hobby.key}>
              <Box component={"span"} mr={"1rem"}>
                {hobby.emoji}
              </Box>
              {hobby.label}
            </li>
          ))}
        </ul>
      </>
    );
  }

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      mt={"3rem"}
    >
      <Terminal text={upDownLoad()} />
      <Terminal text={aboutMeText()} />
      <Terminal text={skillsText()} />
      <Terminal text={miscText()} />

      <Modal
        onClick={closeModal}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Terminal
          style={{
            width: "100% !important",
            height: "100% !important",
            margin: "0 !important",
            boxShadow: "#27c93f 8px 8px 12px 0px",
          }}
          text={upLoadModal()}
        />
      </Modal>
    </Box>
  );
}
