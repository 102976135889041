import self from "../img/self.png";
import lords from "../img/lords.png";
import zeroonze from "../img/zeroonze.png";
import opaulista from "../img/opaulista.png";
import deliciaslu from "../img/deliciaslu.png";
import nutri from "../img/nutri.png";
import diskadega from "../img/diskadega.png";

export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];

export const info = {
  key: 1,
  firstName: "Markus",
  lastName: "Vinicyus",
  initials: "MV",
  position: "a Tech lead",
  selfPortrait: self,
  gradient: `-webkit-linear-gradient(135deg, ${colors})`,
  baseColor: colors[0],
  miniBio: [
    {
      emoji: "☕",
      text: "fueled by coffee",
      key: 1,
    },
    {
      emoji: "🌎",
      text: "based in the BR",
      key: 2,
    },
    {
      emoji: "💼",
      text: "Tech lead at Domvs IT",
      key: 3,
    },
    {
      emoji: "📧",
      text: "markus.lemos@domvsit.com.br",
      key: 4,
    },
  ],
  socials: [
    {
      link: "https://web.facebook.com/markusvinicyus/",
      icon: "fa fa-facebook",
      key: 1,
    },
    {
      link: "https://www.instagram.com/markus.vinicyus/",
      icon: "fa fa-instagram",
      key: 2,
    },
    {
      link: "https://github.com/mvinicyus",
      icon: "fa fa-github",
      key: 3,
    },
    {
      link: "https://www.linkedin.com/in/markus-vinicyus-9b03682a/",
      icon: "fa fa-linkedin",
      key: 4,
    },
    {
      link: "https://twitter.com/markus_vinicyus",
      icon: "fa fa-twitter",
      key: 5,
    },
  ],
  bio: "Hi! I'm Markus. I'm a tech lead specialist in microsoft .net, full stack developer. and associate arquitect aws. I studied systems development and IT management at universidade paulista UNIP, studying postgraduate studies in distributed software architecture at PUC minas, I like to marathon series and movies with my wife and son, as well as going out to eat. You should hire me!",
  skills: {
    key: 1,
    proficientWith: [
      {
        description: ".net c#",
        key: 1,
      },
      {
        description: "sql server",
        key: 2,
      },
      {
        description: "mysql",
        key: 3,
      },
      {
        description: "mongodb",
        key: 4,
      },
      {
        description: "documentDb",
        key: 5,
      },
      {
        description: "dynamoDB",
        key: 6,
      },
      {
        description: "javascript",
        key: 7,
      },
      {
        description: "react",
        key: 8,
      },
      {
        description: "git",
        key: 9,
      },
      {
        description: "github",
        key: 10,
      },
      {
        description: "bootstrap",
        key: 11,
      },
      {
        description: "html5",
        key: 12,
      },
      {
        description: "css3",
        key: 13,
      },
      {
        description: "figma",
        key: 14,
      },
    ],
    exposedTo: [
      { key: 1, description: ".net" },
      { key: 2, description: "sql" },
      { key: 3, description: "nosql" },
      { key: 4, description: "aws" },
    ],
  },
  hobbies: [
    {
      label: "reading",
      emoji: "📖",
      key: 1,
    },
    {
      label: "theater",
      emoji: "🎭",
      key: 2,
    },
    {
      label: "movies",
      emoji: "🎥",
      key: 3,
    },
    {
      label: "cooking",
      emoji: "🌶",
      key: 4,
    },
  ],
  portfolio: [
    {
      title: "Lord`s Burguer",
      live: "https://lordsburguer.chamanospedidos.com",
      source: "https://lordsburguer.chamanospedidos.com",
      image: lords,
      key: 1,
    },
    {
      title: "Zero Onzefood",
      live: "https://zeroonzefood.chamanospedidos.com",
      source: "https://zeroonzefood.chamanospedidos.com",
      image: zeroonze,
      key: 1,
    },
    {
      title: "Delicias da Lu",
      live: "https://deliciasdaludelivery.chamanospedidos.com",
      source: "https://deliciasdaludelivery.chamanospedidos.com",
      image: deliciaslu,
      key: 1,
    },
    {
      title: "Pizzaria O Paulista",
      live: "https://pizzariaopaulista.chamanospedidos.com",
      source: "https://pizzariaopaulista.chamanospedidos.com",
      image: opaulista,
      key: 2,
    },
    {
      title: "Nutri Comer Bem",
      live: "https://nutricomerbem.chamanospedidos.com",
      source: "https://nutricomerbem.chamanospedidos.com",
      image: nutri,
      key: 1,
    },
    {
      title: "Disk Adega",
      live: "https://diskadega.chamanospedidos.com",
      source: "https://diskadega.chamanospedidos.com",
      image: diskadega,
      key: 1,
    },
  ],
};
